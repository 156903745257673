import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/easter-isle.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/landscape.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/maldives.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/stamp.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/about/arabesque.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/about/curved-arrow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/book/nav-arrow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/book/passport.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/contact/or.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/chat-bubble.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/check-list.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/invoice.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/money-hand.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/plane-book.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/plane.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/planning-check.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/puzzle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/quote.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/speed-clock.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/trust.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/menu.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/price/divider.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/logo.svg");
